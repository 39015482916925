import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  userDetail: any = {};
  currentUrl = window.location.href;

  constructor(
        private authService: AuthService,
        private router: Router,
  ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.userDetail = this.authService.getProfileDetails() != null ? this.authService.getProfileDetails() : null;

      let status = false;
      let redirectTo = '';
      if(!this.currentUrl.includes('api')) {
        if(!this.userDetail) {
          this.router.navigate(['/auth/login']);
        } else {
          switch(this.userDetail.role_id.toString()) {
            case "1":
              status =  true;
              break;
            case "2":
              if(this.userDetail && this.userDetail.projectRole) {
                if(this.currentUrl.includes('/projects/create') ||
                (this.userDetail.projectRole.role !='Project Manager' && this.currentUrl.includes('/projects/update')) ||
                (this.userDetail.projectRole.role =='Site Incharge' && this.currentUrl.includes('/quality-approval')) ||
                (this.userDetail.projectRole.role =='Site Incharge' && this.currentUrl.includes('/approve-quality'))
                ) {
                  status = false;
                } else {
                  status = true;
                }
                if((this.currentUrl.includes('activity/update') || this.currentUrl.includes('activity/add')) && this.userDetail.projectRole.role !='Project Manager') {
                  status = true;
                }
                if(this.currentUrl.includes('stock-out') && this.userDetail.projectRole.role == 'Site Incharge') {
                  status = false;
                }
              }else {
                // status = false;
                if(this.currentUrl.includes('/projects/create') ||
                (this.userDetail.projectRole != undefined && ((this.userDetail.projectRole.role !='Project Manager' && this.currentUrl.includes('/projects/update')) ||
                (this.userDetail.projectRole.role =='Site Incharge' && this.currentUrl.includes('/quality-approval')) ||
                (this.userDetail.projectRole.role =='Site Incharge' && this.currentUrl.includes('/approve-quality'))))
                ) {
                  status = false;
                } else {
                  status = true;
                }
                if((this.currentUrl.includes('activity/update') || this.currentUrl.includes('activity/add')) && this.userDetail.projectRole.role !='Project Manager') {
                  status = true;
                }
                if(this.currentUrl.includes('stock-out') && this.userDetail.projectRole.role == 'Site Incharge') {
                  status = false;
                }
              }
              break;
            case "3":
              if(this.userDetail && this.userDetail.projectRole) {
                if(this.currentUrl.includes('/projects/create') || this.currentUrl.includes('projects/update') ||
                (this.userDetail.projectRole != undefined && (this.userDetail.projectRole.role == 'Purchase Manager' && this.currentUrl.includes('/activity/update'))) ||
                this.currentUrl.includes('projects/activity')) {
                  status = false;
                } else {
                  status = true;
                }
                if((this.userDetail.projectRole.role == 'Purchase Manager' && this.currentUrl.includes('quality'))) {
                  status = true;
                }
                if((this.userDetail.projectRole.role == 'Store Manager' && this.currentUrl.includes('quality'))) {
                  status = false;
                }
              } else {
                if(this.currentUrl.includes('/projects/create') || this.currentUrl.includes('projects/update') ||
                (this.userDetail.projectRole != undefined && (this.userDetail.projectRole.role == 'Purchase Manager' && this.currentUrl.includes('/activity/update'))) ||
                this.currentUrl.includes('projects/activity')) {
                  status = false;
                } else {
                  status = true;
                }
              }
              break;
            }
          if(!status) {
              this.router.navigate(['projects/list']);
          }
        }
      } else {
        status = true;
      }
      return status;
  }

}
