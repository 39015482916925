import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Storage } from '@ionic/storage';

interface ICookieOptions {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
}

const EXPIRES_AT = new Date();
EXPIRES_AT.setDate(EXPIRES_AT.getDate() + 1); // 1 day

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  dataStorage: any[] = [];
  constructor(
    private cookieService: CookieService,
    private storage: Storage
  ) { }

  setCookie(key: string, value: any, options: ICookieOptions = {}): void {
    const [ext, name] = location.hostname.split('.').reverse();
    const domainName = location.hostname === 'localhost' ? 'localhost' : `.${[name, ext].join('.')}`;
    const isSecure = location.hostname === 'localhost' ? false : true;
    const { expires = EXPIRES_AT, path = '/', domain = domainName, secure = isSecure } = options;
    this.cookieService.set(key, JSON.stringify(value), expires, path, domain, secure);
  }

  getCookie(key: string) {
    const cookie = this.cookieService.get(key);
    return cookie ? JSON.parse(cookie) : null;
  }

  deleteAllCookies() {
    const path = '/';
    return this.cookieService.deleteAll(path);
  }

  setLocalStorage(key: string, value: any) {
    const data = JSON.stringify(value);
    localStorage.setItem(key, data);
  }

  getLocalStorage(key: string): any {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  }

  deleteAllLocalStorage() {
    return localStorage.clear();
  }

  setStoredData(data: any) {
    const copiedObj = JSON.parse(JSON.stringify(data));
    this.dataStorage = copiedObj;
  }

  getStoredData() {
    const copiedObj = JSON.parse(JSON.stringify(this.dataStorage));
    return copiedObj;
  }

  setUserDetails(userdata: any): void {
    this.storage.set('user', JSON.stringify(userdata));
  }

  getUserDetails(): Promise<any> {
    return this.storage.get('user').then((value:string) => {
      let user = JSON.parse(value);
      return user;
    });
  }
}
