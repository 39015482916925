import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import { tap } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import API_URL from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  userProfile: any;
  sharedData: BehaviorSubject<any> = new BehaviorSubject(null);
  get sharedDetails$(): Observable<any> {
    return this.sharedData.asObservable();
  }

  profileDetails: BehaviorSubject<any> = new BehaviorSubject(null);

  get profileDetails$(): Observable<any> {
    return this.profileDetails.asObservable();
  }

  constructor(
    public http: HttpClient,
    private storageService: StorageService,
    private router: Router,
  ) {
    this.userProfile = {};
    this.userProfile = localStorage.getItem('profileDetails')? JSON.parse(localStorage.getItem('profileDetails')) : null;
   }

  register(data) {
    return this.http.post(
      `${API_URL}register`, data
    );
  }

  login(data) {
    return this.http.post(
      `${API_URL}user/login`, data,
    );
    // return Observable.create(observer => {
    //     observer.next({
    //       "success": true,
    //       "message": "Valid user credentials!",
    //       "data": {
    //           "id": 3,
    //           "username": "user1",
    //           "email_id": "user1@user.com",
    //           "phone": "8870467342"
    //       }
    //     });
    //     observer.complete();
    // });
  }

  verifyMobileEmail(data) {
    return this.http.post(
      `${API_URL}verification`, data
    );
  }

  setCurrentUser(user) {
    this.storageService.setCookie('isLoggedIn', true);
    this.storageService.setCookie('user', user);
    this.storageService.setUserDetails(user);
  }

  setProfileDetails(data): any {
    this.profileDetails.next({
      id: data.id ? data.id : null,
      role_id: data.role_id ? data.role_id : null,
      username: data.username ? data.username : null,
      email_id: data.email_id ? data.email_id : null,
      phone: data.phone ? data.phone : null,
      projectRole: data.projectRole ? data.projectRole : null,
    });
    return this.storageService.setLocalStorage('profileDetails', data);
  }

  is_LoggedIn() {
    return this.storageService.getCookie('isLoggedIn');
  }

  getProfileDetails(): any {
    return this.storageService.getLocalStorage('profileDetails');
  }

  setProfileDetails$() {
    const data = this.storageService.getLocalStorage('profileDetails');
    if (!data) {
      return
    }
    this.profileDetails.next({
      id: data.id ? data.id : null,
      username: data.username ? data.username : null,
      email_id: data.email_id ? data.email_id : null,
      phone: data.phone ? data.phone : null,
    });
    this.storageService.setLocalStorage('profileDetails', data);
  }

  logout() {
    this.storageService.deleteAllCookies();
    localStorage.removeItem('profileDetails');
    // localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['auth/login']);
    // return this.http.get(
    //   `${API_URL}logout`
    // ).pipe(
    //   tap(data => {
    //     this.storageService.deleteAllCookies();
    //     localStorage.clear();
    //   })
    // );
  }

  getUserDetails(): any {
    return this.storageService.getCookie('user');
  }

  forgetPassword(data) {
    return this.http.post(
      `${API_URL}forgetPassword`, data
    );
  }



  resetPassword(data) {
    return this.http.post(
      `${API_URL}resetPassword`, data
    );
  }

  createPassword(data) {
    return this.http.post(
      `${API_URL}createPassword`, data
    );
  }

  getUserInfo(){
    return this.storageService.getUserDetails()
  }

  userProfileUpdate(data){
    return this.http.post(
      `${API_URL}user/profile_update`,data
    )
  }

  userChangePassword(data){
    return this.http.post(
      `${API_URL}user/change_password`,data
    )
  }

  isOwner() {
    if(this.userProfile && this.userProfile.role_id != undefined && this.userProfile.role_id.toString() == '1') {
      return true;
    } else {
      return false;
    }
  }
  isProjectOwner() {
    if(this.userProfile && this.userProfile.projectRole && this.userProfile.projectRole.role == 'Project Owner') {
      return true;
    } else {
      return false;
    }
  }
  isProjectManager() {
    if(this.userProfile && this.userProfile.projectRole && this.userProfile.projectRole.role == 'Project Manager') {
      return true;
    } else {
      return false;
    }
  }
  isProjectCoordinator() {
    if(this.userProfile && this.userProfile.projectRole && this.userProfile.projectRole.role == 'Project Co-Ordinator') {
      return true;
    } else {
      return false;
    }
  }
  isPurchaseManager() {
    if(this.userProfile && this.userProfile.projectRole && this.userProfile.projectRole.role == 'Purchase Manager') {
      return true;
    } else {
      return false;
    }
  }
  isSiteIncharge() {
    if(this.userProfile && this.userProfile.projectRole && this.userProfile.projectRole.role == 'Site Incharge') {
      return true;
    } else {
      return false;
    }
  }
  isStoreManager() {
    if(this.userProfile && this.userProfile.projectRole && this.userProfile.projectRole.role == 'Store Manager') {
      return true;
    } else {
      return false;
    }
  }
  isQualityManager() {
    if(this.userProfile && this.userProfile.projectRole && this.userProfile.projectRole.role == 'Quality Manager') {
      return true;
    } else {
      return false;
    }
  }
}
